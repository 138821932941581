import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404: Not found" />
    <h1>Ouch!</h1>
    <p>You just found a page that doesn&#39;t exist...</p>
  </PageLayout>
)

export default NotFoundPage
